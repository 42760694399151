export const environment = {
    production: true,
    environmentName: "Production",
    gcServer: {
        protocol: "https",
        ip: "api.gc.multigroup.com",
        port: "3000",
        version: "api/v2",
    },
    gcServerV3: {
        protocol: "https",
        ip: "api.gc.multigroup.com",
        port: "3000",
        version: "api/v3",
    },
    gescomBaasServer: {
        protocol: "https",
        ip: "gc.multigroup.com",
        port: "80",
        version: "baas/v1",
    },
    gescomRestApiServer: {
        protocol: "https",
        ip: "gc.multigroup.com",
        port: "80",
        version: "restapi/v1",
    },
    baasBaasServer: {
        protocol: "https",
        ip: "baas.multigroup.com",
        port: "80",
        version: "baas/v1",
    },
    baasRestApiServer: {
        protocol: "https",
        ip: "baas.multigroup.com",
        port: "80",
        version: "restapi/v1",
    },
    sapApiServerV3: {
        protocol: "https",
        ip: "api.sap.multigroup.com",
        port: "80",
        version: "api/v3",
    },
    sapApiServerV2: {
        protocol: "https",
        ip: "api.sap.multigroup.com",
        port: "3000",
        version: "api/v2",
    },
    sapApiServer: {
        protocol: "https",
        ip: "api.sap.multigroup.com",
        port: "3000",
        version: "api/v1",
    },
    sapApiServerV4: {
        protocol: "https",
        ip: "api.sap.multigroup.com",
        port: "3000",
        version: "api/v4",
    },
    apiAuth: {
        protocol: "https",
        ip: "api.auth.multigroup.com",
        port: "3000",
        version: "api/v5",
    },
    apiPortal: {
        protocol: "https",
        ip: "api.portal.multigroup.com",
        port: "3000",
        version: "api/v1",
    },
    gcServerV3PRD: {
        protocol: "https",
        ip: "api.gc.multigroup.com",
        port: "3000",
        version: "api/v3",
    },
};
